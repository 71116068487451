import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/photo.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              GIỚI THIỆU <span className="purple"> BẢN THÂN</span> 
            </h1>
            <p className="home-about-body">
            Làm việc có trách nhiệm, tập trung vào chất lượng và hiệu quả, đồng thời luôn tìm kiếm kiến thức và kỹ năng mới.
            <br />         <br />
             
          Xin chào, tôi là <span className="purple">Nguyễn Tuấn Khoa </span>
          đến từ <span className="purple">TP.Hồ Chí Minh.</span>
            <br /> Tôi là sinh viên năm  tư ngành công nghệ phần mềm tại Đại học Kinh tế - Tài chính thành phố Hồ Chí Minh UEF.
            <br />

          </p>
         
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Tìm hiểu thêm</h1>
            <p>
              Kết nối với tôi
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/yanteams"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
             
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
