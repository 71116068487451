import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import fituef from "../../Assets/Projects/uef.png";
import thoitrang from "../../Assets/Projects/tt.png";
import nike from "../../Assets/Projects/nike.jpg";
import bv from "../../Assets/Projects/bv.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Dự án gần đây <strong className="purple">của tôi </strong>
        </h1>
        <p style={{ color: "white" }}>
        Dưới đây là một số dự án tôi đã thực hiện gần đây.
                </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nike }
              isBlog={false}
              title="ECOMMERCE SHOES"
              description="Phát triển hệ thống cửa hàng bán giày online qua ứng dụng di động và website."
              ghLink="https://github.com/yanteams/DO-AN-UEF-UNIVERSITY"
              demoLink="https://nike-yan.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={fituef}
              isBlog={false}
              title="QuanLyKhoaCNTTUEF"
              description="Một dự án web full stack được xây dựng bằng ASP.NET, EF Core. Ứng dụng này có thể được sử dụng để duyệt và quản lý các sự kiện của hệ thống trường học."
              ghLink="https://github.com/yanteams/QuanLyKhoaUEF"
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={thoitrang}
              isBlog={false}
              title="Fashion Management System"
              description="Hệ thống quản lý thời trang (FMS) là một hệ thống quản lý được thiết kế để hỗ trợ các doanh nghiệp trong ngành thời trang trong việc quản lý hoạt động kinh doanh của mình. FMS cung cấp các công cụ để quản lý sản xuất, tồn kho, bán hàng, tài chính và quản lý khách hàng.
              "
              ghLink="https://github.com/yanteams/Fashion-Management-System"
              demoLink=""              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bv}
              isBlog={false}
              title="HospitalSystem"
              description="Hệ thống quản lý bệnh viện là hệ thống thông tin được thiết kế nhằm hỗ trợ việc quản lý thông tin bệnh nhân và chăm sóc sức khỏe tại bệnh viện.
              "
              ghLink="https://github.com/yanteams/HospitalSystem"
              demoLink=""              
            />
          </Col>

          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
