import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          Xin chào, tôi là <span className="purple">Nguyễn Tuấn Khoa </span>
          đến từ <span className="purple">TP.Hồ Chí Minh.</span>
            <br /> Tôi là sinh viên năm thứ tư ngành công nghệ phần mềm tại Đại học Kinh tế - Tài chính thành phố Hồ Chí Minh UEF.
            <br />

          </p>
         

   
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
